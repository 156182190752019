
(function() {
    'use strict';
    const utitlity = exports;

    utitlity.integerToVowel = function(e){
    
        if(!Number.isInteger(e))
            throw "El index ingresado no es un dato numérico válido."
    
        var vowel = ""
        switch(e){
            case 0 : vowel = "a. "; break;
            case 1 : vowel =  "b. "; break;
            case 2 : vowel =  "c. "; break;
            case 3 : vowel =  "d. ";  break;
            case 4 : vowel =  "e. "; break;
            default : break;
        }
        return vowel;
    };


    utitlity.messageCode =  function(ex){
        var exception   =  ex;
        var detailsText = ""

        var decode = function(obj){
            var detailsText = ""
            if(Array.isArray(obj))
            {
                var arrays = Object.keys(obj);
                arrays.forEach((val)=>{
                    detailsText +=  `${!isNaN(val) ? "" :  val + " => " }  ${decode(obj[val])} \n` ; 
                })
            }
            else if(typeof obj === 'object' && obj !== null)
            {
                var keys = Object.keys(obj);
                keys.forEach((val)=>{
                    detailsText +=  `${!isNaN(val) ? "" :  val + " => " }  ${decode(obj[val])} \n` ; 
                })
            }else{
                detailsText +=  obj + "\n"
            }
            return detailsText
        }
     
        if (exception.response){
            var response =  exception.response; 
            if(typeof response.data === 'object' && response.data !== null){
                if(response.data.errors){
                    detailsText = decode(response.data.errors);   
                }
            }else if(Array.isArray(response.data)) {
                var obj = response.data
                decode(obj)
            }else if(response.data.trim()){
                var data =  response.data
                detailsText =  data;
            }else{
                if(response.status === 401){
                    detailsText = "Parece que su sesión ha finalizado, favor cerrar sesión e iniciar nuevamente";
                }
            }
        }
        else if (exception.request) {
            detailsText = `Error : No se obtuvo una respuesta válida a la petición al servidor`
        }else{
            detailsText = ex
        }
        return detailsText
    };
  })();