<template>
    <div class="flex justify-content-between align-items-center mb-1">
        <span class="p-input-icon-left w-full">
            <i class="pi pi-search" />
            <InputText v-model="searchTerm" placeholder="Nombre, Puesto, Facultad"  @keyup="handleKeyUp" @keypress="handleKeyPress"  class="w-full"/>
        </span>
    </div>

    <DataTable :value="empleadoList" :rowHover="true"  :selectionMode="Mode"  responsiveLayout="scroll" :loading="loading" v-model:selection="selectedEmpleados" :metaKeySelection="false" dataKey="emp_codigo">
        <template #loading>
            Cargando empleados. Espere por favor...
        </template>
        <template #empty>
            Ningún empleado encontrado.
        </template>
        <Column :selectionMode="Mode" headerStyle="width: 3rem"></Column>
        <Column field="emp_codigo" header="Código" headerStyle="width: 75px"></Column>
        <Column header="Empleado">
            <template #body="slotProps">
                <div class="flex flex-column">
                    <span class="font-semibold">{{slotProps.data.emp_nombres_apellidos}} </span>
                    <small> {{slotProps.data.emp_sede}}</small>
                </div>
            </template>
        </Column>
        <Column  header="Empleado">
            <template #body="slotProps">
                <div class="flex flex-column">
                    <span>{{slotProps.data.emp_puesto}} </span>
                    <small> {{slotProps.data.emp_unidad}}</small>
                </div>
            </template>
        </Column>
        <template #footer>
            <Paginator :rows="10" :totalRecords="empleadoPagination.totalRecords" 
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[10,20,50,100]" responsiveLayout="scroll"
                currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords} Empleados" @page="onPage($event)"></Paginator>
        </template>
    </DataTable>
</template>

<script>


import EmpleadoService from '../../service/empleadoService'

let timer,
timeoutVal = 1000;

export default {

    empleadoService :  null,
    props: {
        selectedItems :null,
        Mode :{
            type: String,
            default : "multiple"
        }
    },

    emits : ["update:selectedItems"],
    data(){

        return{
            empleadoPagination : [],
            selectedEmpleados: this.selectedItems,
            empleadoList : [],
            searchTerm:  "",
            loading  : true
        }
    },

    created(){
        this.empleadoService  =  new EmpleadoService(this.axios);
    },

    async mounted(){
        this.getEmpleados(1,10,this.searchTerm);
    },

    methods: {

        async getEmpleados(page, pageSize, searchTerm){
            await this.empleadoService.getAllEmpleado(page,pageSize,searchTerm)
            .then(data => this.empleadoPagination = data)
            .catch(ex => this.$catchError(ex));
            this.loading = false;
            this.empleadoList =  this.empleadoPagination.data
        },
       
       async onPage(event) {
            //event.page: New page number
            //event.first: Index of first record
            //event.rows: Number of rows to display in new page
            //event.pageCount: Total number of pages
            this.getEmpleados(++event.page, event.rows,null);

        },
        // eslint-disable-next-line no-unused-vars
        handleKeyPress(e) {
            window.clearTimeout(timer);
        },
        // eslint-disable-next-line no-unused-vars
        handleKeyUp(e) {
            this.loading =  true;
            window.clearTimeout(timer); 
            timer = window.setTimeout(() => {
                this.getEmpleados(this.empleadoPagination.page, this.empleadoPagination.pageSize,this.searchTerm);
               this.loading =  false;

            }, timeoutVal);

        }
    },
    watch:{
        // eslint-disable-next-line no-unused-vars
        selectedEmpleados: function(val){
           this.$emit('update:selectedItems', val)
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}
</style>