<template>
    <div class="flex  flex-column md:flex-row justify-content-between align-items-center mb-1" v-if="EditMode">
        <div class="flex flex-grow-1 my-1">
            <span class="p-input-icon-left w-full  mr-4 p-fluid" >
                <i class="pi pi-search" />
                <InputText  placeholder="Nombre de asignatura"   v-model="searchTerm" @keyup="handleKeyUp" @keypress="handleKeyPress"/> 
            </span>
        </div>
        <div class="my-1">
            <SelectButton v-model="selectedStatus" :options="statuses" dataKey="code" class="w-full">
                <template #option="slotProps">
                    <div class="inline-flex font-semibold"  v-tooltip.top="slotProps.option.name">
                        <i class="pi pi-" :class="[slotProps.option.icon,'text-' +  (slotProps.option.code === 'A' ? 'success' : (slotProps.option.code === 'R' ? 'warning' : 'primary'))]"></i>
                        <div class="pl-2 hidden md:block text-xs md:text-base">{{slotProps.option.name}}</div>
                        
                    </div>
                </template>
            </SelectButton>
        </div>
    </div>
    <Divider  class="my-2"/>
    <DataTable :value="planificacionList"  stripedRows  :rowHover="true"  responsiveLayout="scroll" selectionMode="single" dataKey="pld_codigo"
        :loading="loading" contextMenu v-model:contextMenuSelection="selectedPlan" @rowContextmenu="onRowContextMenu" v-model:selection="selectedPlan"
        @row-dblclick="rowFac_dblClick($event)"  class="table-light-success ">
        
        <template #loading>
            Cargando listado de planificación. Espere por favor...
        </template>
        <template #empty>
            Ningúna planificación encontrada.
        </template>
        <!--Begin: Table Body-->
        <!-- ** If is searching: hidden ** -->
        <div v-if="EditMode">
            <Column field="" header="#" headerStyle="width: 50px">
                <template #body="{index}">
                    <span class="font-bold text-gray-900">{{( this.firstIndex + index + 1)}}</span>
                </template>
            </Column>
            <Column  field="plm_mat_nombre" header="ASIGNATURA"  >
                <template #body="{data}">
                    <div class="flex align-items-center">
                        <div class="symbol symbol-40 symbol-circle symbol-light-primary shadow-3">
                            <span class="symbol-label font-size-h4">{{data.plm_mat_nombre[0].toUpperCase()}}</span>
                        </div>
                        <div class="ml-3">
                            <span class="font-bold text-gray-900">{{data.plm_mat_nombre}}</span>
                            <div class="mt-1 font-semibold text-gray-600 text-sm">{{data.pld_nomcil}}  - {{data.pla_nombre.toUpperCase()}} </div>
                        </div>
                    </div>
                </template>
            </Column>
            <Column  header="FACULTAD"  >
                <template #body="{data}">
                    <div class="flex align-items-center">
                        <div class="">
                            <span class="font-bold text-gray-900">{{data.car_nombre}}</span>
                            <div class="mt-1 font-semibold text-gray-600 text-sm">{{data.fac_nombre}}</div>
                        </div>
                    </div>
                </template>
            </Column>
            <Column  header="FECHA" style="width:9rem" >
                <template #body="{data}">
                    <span class="font-bold text-primary">{{DateFormat(data.pld_fecha_estado)}}</span>
                    <div class="mt-1 font-semibold text-gray-600 text-sm">COD:  {{data.pld_codigo}} </div>
                </template>
            </Column>
            <Column  header="ESTADO"   style="width:12rem">
                <template #body="{data}">
                    <span class="label label-lg  label-inline font-semibold" 
                    :class="'label-light-' + (data.pld_estado === 'A' ? 'success' : (data.pld_estado === 'R' ? 'warning' : 'primary') )">{{estado(data.pld_estado)}}</span>
                
                </template>
            </Column>
            
            <Column style="width:120px" >
                <template #header>
                    
                </template>
                <template #body="{data}">
                    <div class="flex">
                        <router-link  class="p-button  p-button-icon-only p-button-text" :to="{ name: 'PlaneacionDetalle', params: { id:  data.pld_codigo} }" >
                            <span class="pi pi-search p-button-icon"></span>
                        </router-link>
                    </div>
                </template>
            </Column>
        </div>
        <div v-else>
            <Column  field="plm_mat_nombre" header="ASIGNATURA"  >
                <template #body="{data}">
                    <div class="flex align-items-center">
                        <div class="symbol symbol-40 symbol-circle symbol-light-primary shadow-3">
                            <span class="symbol-label font-size-h4">{{data.plm_mat_nombre[0].toUpperCase()}}</span>
                        </div>
                        <div class="ml-3">
                            <span class="font-bold text-gray-900">{{data.plm_mat_nombre}}</span>
                            <div class="mt-1 font-semibold text-gray-600 text-sm">{{data.pld_nomcil}}  - {{data.pla_nombre.toUpperCase()}} </div>
                        </div>
                    </div>
                </template>
            </Column>
        </div>
        <!--End: Table Body -->
        <template #paginatorLeft>
                <Button type="button" icon="pi pi-refresh" class="p-button-text" />
         </template>
        <template #footer>
            <Paginator :rows="10" :totalRecords="paginationList.totalRecords" 
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[10,20,50,100]"
                currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords} Planes" @page="onPage($event)">
                <template #left>
                    {{title}}<br>
                </template>
            </Paginator>
        </template>
    </DataTable>

    <ContextMenu  ref="menu" :model="contextMenuItems" />

     <PlanDidacticoCopy  :isVisible="isDialogoCopy"  
        :dataPlan="selectedPlan"
        @cancel_onClick="isDialogoCopy = false;" />
</template>

<script>

import moment from 'moment'
import methods from "@/utilities/generalMethods.js"
import PlanDidacticoCopy from "@/components/LaborDocente/Planificacion/PlanDidacticoCopy";


let timer,
timeoutVal = 1000;

export default {
    
    emits:["onSelected"],
    props:{
        Facultad: {
            type: Object,
            default(){
                return {}
            }
        },
        Carrera: {
            type :  Object,
            default(){
                return {}
            }
        },
        Ciclo: {
            type :  Object,
            default(){
                return {}
            }
        },
        EditMode:{
            type:Boolean,
            default: true
        },

        Service : null
    },


    data(){
        
        return{
            loading :  false,

            statuses: [
                { name: 'En Elaboración', code: 'E', icon:"pi-pencil"}, 
                { name: 'En Revisión', code: 'R', icon:"pi-sliders-h"}, 
                { name: "Aprobado", code: 'A', icon:"pi-check-square"}, 
                { name : "Todos", code : "T", icon: "pi-list"}
            ],
            selectedStatus : { code : "T"},

            planificacionList : [],
            withPaginationList : [],
            paginationList : { },
            selectedPlan :  null,
            contextMenuItems: [],

            title :  "",
            firstIndex : 0,
            searchTerm :  "",

            isDialogoCopy : false,
        }
    },
    created(){
        this.contextMenuItems = [
            {
                label: 'Copiar a...',
                icon: 'pi pi-copy',
                command: () => {
                    if(!this.$store.getters.evalAuth("btnNew", this.$toast)){
                        return;
                    }
                     this.isDialogoCopy =  true;
                }
            },
            {
                label: 'Nueva versión',
                icon: 'pi pi-check-square',
                command: () => {
                    alert('Nueva versión')
                }
            },
            {
                label: 'Imprmir',
                icon: 'pi pi-print',
                command: () => {
                    try {
                        this.Service.printPlan(this.selectedPlan.pld_codigo).then(res  =>{
                            const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
                            window.open(url, '_blank');
                        })
                    }catch(ex){
                        this.$catchError(ex)
                    }
                }
            }
        ]
    },

    async mounted(){
        this.loading = true
        try{
            this.paginationList  = {
                totalRecords: 0,
                currentPage : 1,
                totalPages: 1,
                pageSize: 10,
            },

            await this.fxPlanificacion_Load(this.paginationList.currentPage, this.paginationList.pageSize, this.searchTerm);
        }catch(ex){
            var msg = methods.messageCode(ex)
            this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
        }finally{
            this.loading = false
        }
        
    },
    methods:{

        async fxPlanificacion_Load(page, pageSize, searchTerm){
            this.planificacionList = [];
            //Asignando restriccion de visualizacion de Todos los datos o solo asignados
            this.title = this.Facultad ? this.Facultad.fac_nombre : "TODAS FACULTADES "
            this.title += " >> " +(this.Carrera ? this.Carrera.car_nombre : "TODAS LAS CARRERAS")

            //Por defecto -1 porque cero es todo
            var fac_codigo  = this.Facultad == null ? -1 : this.Facultad.fac_codigo
            var car_codigo  = this.Carrera == null ? 0 : this.Carrera.car_codigo
            var cil_nombre  =  this.Ciclo == null ? "" : this.Ciclo.cil_descripcion 
            var byEmpCodigo = !this.$store.getters.evalAuth("VerTodos") ? this.$store.getters.getEmpId : 0;

            this.firstIndex = 0;
            await this.Service.getPlanes({
                fac_codigo : fac_codigo, 
                car_codigo : car_codigo,
                cil_nombre : cil_nombre,
                coe_codemp : byEmpCodigo,
                page : page,
                pageSize : pageSize,
                searchTerm : searchTerm ,
                pld_estado : this.selectedStatus.code
            }).then(result => {
                this.paginationList =  {
                    totalRecords: result.totalRecords,
                    currentPage : result.currentPage,
                    totalPages: result.totalPages,
                    pageSize: result.pageSize,
                },
                this.planificacionList = result.data
            })
            
           
        },

        toggle(event) {
           this.$refs.menu.show(event.originalEvent);
        },
         async rowFac_dblClick(event){ //Double Click Row Facultad
            if(!this.EditMode) {
                this.$emit("onSelected", event.data);
                return;
            }
            this.$router.push({ name: 'PlaneacionDetalle' , params: { id:  event.data.pld_codigo}  })
        },
        DateFormat(data) {
            return moment(data).format('DD/MM/YYYY')
        },
        estado(estado){
            if(estado === "A"){
                return "Aprobado"
            }else if(estado ==="R"){
                return "En Revisión"
            }else{
                return "En Elaboración"
            }
        },

        onRowContextMenu(event){
            this.$refs.menu.show(event.originalEvent);
        },
        //Paginator Event
        async onPage(event) {
            //event.page: New page number
            //event.first: Index of first record
            //event.rows: Number of rows to display in new page
            //event.pageCount: Total number of pages
            await this.fxPlanificacion_Load(++event.page, event.rows,null);
             this.firstIndex = event.first;

        },
        // eslint-disable-next-line no-unused-vars
        handleKeyPress(e) {
            window.clearTimeout(timer);
        },
        // eslint-disable-next-line no-unused-vars
        handleKeyUp(e) {
            this.loading =  true;
            window.clearTimeout(timer); 
            timer = window.setTimeout(async () => {
                await this.fxPlanificacion_Load(1, this.paginationList.pageSize,this.searchTerm);
               this.loading =  false;

            }, timeoutVal);

        }
    },
    watch: {
        //eslint-disable-next-line no-unused-vars
        selectedStatus:async function (newValue, oldValue){
            await this.fxPlanificacion_Load(1, this.paginationList.pageSize, this.searchTerm)
        }
    },
    components: { 
        PlanDidacticoCopy 
    },
    
}
</script>


<style scoped lang="scss">
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}
</style>