// JSDoc (http://usejsdoc.org/)

/** @module FactultadService */

/** 
 * @class

*/
export default class FactultadService {
     /**
     * @param axios Axios Instance
     */
    constructor(axios) {
        this.axios = axios;
    }

    /**
     * Obtiene todas las facultades.
     * @return {json} JsonObject
     */
    async getAllFacultad() {
        return this.axios.get('/Facultad').then(res => res.data);
    }

    /**
     * Obtiene la facultad por codigo
     * @param  {number} codigo - Codigo Facultad
     * @return {json} JsonObject
     */
     async getFacultad(codigo){
        return this.axios.get(`/Facultad/${codigo}`).then(res => res.data);
    }


//#region ***** Carrera *****

    /**
     * Obtiene las carreras por facultad.
     * @param  {number} codigo - Codigo Facultad
     * @return {json} JsonObject
     */
    async getCarreraByFacultad(codigo){
        return this.axios.get(`/Carrera/GetByFacultad/${codigo}`).then(res => res.data);
    }

    /**
    * Actualiza competencia carrera.
    * @param {json} data Parámetros de Entrada.
    * @param {Integer} data.car_codigo Código de Carrera
    * @param {String} data.car_competencia competencia especifica de carrera
   
    * @return {json} JsonObject
    */
    async updateCarrera(data){
        return this.axios.put(`/Carrera/${data.car_codigo}`,data.car_competencia,{
            headers: 
            {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

    /**
     * Obtiene carrera por código.
     * @param  {number} codigo - Codigo de carrera
     * @return {json} JsonObject 
     */
    async getCarrerra(codigo) {
        return this.axios.get(`/Carrera/${codigo}`).then(res => res.data);
    }

   
//#endregion 



//#region  ****Plan Academico */
    
     /**
     * Obtiene plan por carrera.
     * @param  {number} codigo - Codigo de carrera
     * @return {json} JsonObject 
     */
    async getPlanByCarrera(codigo){
        return this.axios.get(`/PlanAcad/GetByCarrera/${codigo}`).then(res => res.data);
    }

    /**
     * Actualiza Area de Formacion.
     * @param {json} data parametros de entrada
     * @param {Integer} data.area_formacion  Título de area de formacion
     * @param {Integer} data.competencia Descripcion de competencia 
     * @return {json} JsonObject 
     */
    async updatePlanAcademico(codigo,data){
        return this.axios.put(`/PlanAcad/UpdateAreaFormacion/${codigo}`,data,{
            headers: 
            {
                "Content-Type": "application/json"
            }
        }).then(res=> res.data);
    }

    /**
     * Actualiza plan por Academico.
     * @param {Integer} codigo Codigo de plan academico 
     * @param {json} data parametros de entrada
     * @param {Integer} data.area_formacion  Título de area de formacion
     * @param {Integer} data.competencia Descripcion de competencia 
     * @return {json} JsonObject 
     */
     async createPlanAcademico(codigo,data){
        return this.axios.post(`/PlanAcad/CreateAreaFormacion/${codigo}`,data,{
            headers: 
            {
                "Content-Type": "application/json"
            }
        }).then(res=> res.data);
    }

    /**
     * Obtiene las areas de formacion de un plan academico.
     * @param  {number} codigo - Codigo de Plan academico
     * @return {json} JsonObject 
     */
    async getAreaFormacion(codigo){
        return this.axios.get(`/PlanAcad/GetPlan_AreasForm/${codigo}`).then(res => res.data);
    }

    /**
     * Obtiene plan por código plan academico.
     * @param  {number} codigo - Codigo de plan
     * @return {json} JsonObject 
     */
    async getPlan(codigo){
        return this.axios.get(`/PlanAcad/${codigo}`).then(res => res.data);
    }


    /**
         * Eliminar area de formacion de plan academico
         * @param {Integer} codigo Codigo del area de formacion
         * @return {String} Mensaje de repuesta
         */
    async deleteAreaFormacion(codigo) {
        return this.axios.delete(`/PlanAcad/DeletePlan_AreaForm/${codigo}`).then(res => res.data).catch(ex =>  {throw ex.response.data});
    }
//#endregion

//#region ****** Asignatura ******

    /**
     * Obtiene asignaturas por código de plan.
     * @param  {number} codigo - Codigo de plan
     * @return {json} JsonObject 
     */
    async getAsignaturaByPlanAcad(codigo){
        return this.axios.get(`/Asignatura/GetByPlanAcad/${codigo}`).then(res => res.data);
    }

    //HttpGet
    async getAsignatura(codigo){
        return this.axios.get(`/Asignatura/${codigo}`).then(res => res.data);
    }

    //HttpPut
    async updateAsignatura(data){
        return this.axios.put(`/Asignatura/${data.plm_codigo}`,data.plm_area_form,{
            headers: 
            {
                "Content-Type": "application/json"
            }
        }).then(res=> res.data);
    }

    //#endregion 
 }