<template>
     <!--Component : Agregar nueva planificacón -->
    <Dialog v-model:visible="createVisible" :style="{width: '640px'}" :modal="true" class="p-fluid" @hide= "hide_event"  :closeOnEscape="false">
        <template #header>
            <div class="flex flex-column align-items-center w-full">
                <h4 class="font-semibold text-gray-900 mb-0 pb-0">Planificación Didáctica</h4>
                <small  class="text-muted text-base  font-semibold text-center">Copiar planificación de asignatura</small>
            </div>
        </template>
        <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            
             <div class="flex-grow-1 mb-3">
                <div class="flex justify-content-between flex-wrap ">
                    <div class="flex mr-1">
                        <a href="javascript:void(0)" class="font-bold mr-2 text-lg text-gray-800 hover:text-primary" >{{selectedPlan.plm_mat_nombre}}</a>
                        
                    </div>
                    <div class="lg:my-0 my-1">
                        <a href="javascript:void(0)" class="label label-xs  label-light-primary label-inline font-bold ml-2 py-3"> {{selectedPlan.pla_nombre}} </a>
                        <a href="javascript:void(0)" class="label label-xs label-light-danger label-inline font-semibold ml-1 py-3">{{selectedPlan.pld_nomcil}} </a>
                    </div>
                </div>
                <div class="flex flex-wrap justify-content-between ">
                    <div class="flex flex-column flex-grow-1 ">
                        <!-- Bar Info -->
                        <div class="flex flex-wrap mb-2">
                            <a href="javascript:void(0)" class=" flex align-items-center text-gray-600 font-semibold hover:text-primary sm:mr-5 mb-2"> 
                                <i class="pi pi-th-large pi-fw  mr-1"></i> {{selectedPlan.fac_nombre}}</a>
                            <a href="javascript:void(0)" class="flex align-items-center text-gray-600 font-semibold sm:mr-5 mb-2"> 
                                <i class="pi pi-shield pi-fw  mr-1"></i> {{selectedPlan.car_nombre}}</a>

                        </div>
                    </div>
                </div>
                <Divider type="dashed"  class="p-divider-danger my-0"/>
             </div>
            <h5  class="font-semibold text-lg pb-0 mb-0 "><AngleDoublesvg/>Copiar A : </h5>

            <div class="formgrid grid my-2">
                
                <div class="col-8 field">
                    <label for="fac_codigo" :class="{'p-error':v$.selectedFacultad.$invalid && submitted}">Facultad</label>
                    <Dropdown :disabled="true"  id="fac_codigo"  v-model="selectedFacultad" :options="facultadList"   placeholder="Seleccione Facultad" class="w-full"  
                        @change="selFacultad_onChange($event)" :class="{'p-invalid':v$.selectedFacultad.$invalid && submitted}">
                        <template #value="slotProps">
                            <div  v-if="slotProps.value">
                                <span>{{slotProps.value.fac_nombre}}</span>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <span>{{slotProps.option.fac_nombre}}</span>
                        </template> 
                    </Dropdown>
                    <small v-if="(v$.selectedFacultad.$invalid && submitted) || v$.selectedFacultad.$pending.$response" class="p-error">{{v$.selectedFacultad.required.$message}}</small>
                </div>
                <div class="col field">
                    <label for="fac_codigo" :class="{'p-error':v$.selectedCiclo.$invalid && submitted}">* Ciclo</label>
                    <Dropdown  id="fac_codigo"  v-model="selectedCiclo" :options="cicloList"   placeholder="Seleccione Ciclo" :class="{'p-invalid':v$.selectedCiclo.$invalid && submitted}">
                            <template #value="slotProps">
                            <div  v-if="slotProps.value">
                                <span>{{slotProps.value.cil_descripcion}}</span>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <span>{{slotProps.option.cil_descripcion}}</span>
                        </template> 
                    </Dropdown>
                    <small v-if="(v$.selectedCiclo.$invalid && submitted) || v$.selectedCiclo.$pending.$response" class="p-error">{{v$.selectedCiclo.required.$message}}</small>
                </div>
            </div>
            <div>
                <div class="field">
                    <label for="car_codigo" :class="{'p-error':v$.selectedCarrera.$invalid && submitted}">Carrera</label>
                    <Dropdown id="car_codigo" v-model="selectedCarrera" :options="carreraList" optionLabel="car_nombre" :class="{'p-invalid':v$.selectedCarrera.$invalid && submitted}"
                        :filter="true" placeholder="Seleccione Carrera" :showClear="true" class="w-full" :loading="loadingCarrera"  @change="selCarrera_onChange($event)">
                        <template #empty>
                            <div class="">Seleccione Facultad </div>
                        </template>
                        <template #value="slotProps">
                            <div  v-if="slotProps.value">
                                <div>{{slotProps.value.car_nombre}}</div>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <div>
                                <div>{{slotProps.option.car_nombre}}</div>
                            </div>
                        </template>
                    </Dropdown>
                    <small v-if="(v$.selectedCarrera.$invalid && submitted) || v$.selectedCarrera.$pending.$response" class="p-error">{{v$.selectedCarrera.required.$message}}</small>
                </div>

                <div class="formgrid grid">
                    <div class="field col-3">
                        <label for="pla_codigo" :class="{'p-error':v$.selectedPlanAcademico.$invalid && submitted}" >Plan</label>
                        <Dropdown id="pla_codigo" v-model="selectedPlanAcademico" :options="planAcademicoList" optionLabel="pla_nombre" :class="{'p-invalid':v$.selectedPlanAcademico.$invalid && submitted}" 
                            :filter="true" placeholder="Plan" :showClear="true" class="w-full" :loading="loadingPlan" @change="selPlanAcad_onChange($event)">
                            <template #empty>
                                <div class="">No hay planes asociados </div>
                            </template>
                            <template #value="slotProps">
                                <div  v-if="slotProps.value">
                                    <div>{{slotProps.value.pla_nombre}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div class="ml-1">{{slotProps.option.pla_nombre}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-if="(v$.selectedPlanAcademico.$invalid && submitted) || v$.selectedPlanAcademico.$pending.$response" class="p-error">{{v$.selectedPlanAcademico.required.$message}}</small>
                    </div>
                    <div class="field col">
                        <label for="plm_codmat" :class="{'p-error':v$.selectedAsignatura.$invalid && submitted}">Asignatura </label>
                        <Dropdown id="plm_codmat" v-model="selectedAsignatura" :options="asignaturaList" optionLabel="plm_mat_nombre" :filter="true" placeholder="Seleccione Asignatura"  
                        class="w-full" :loading="loadingAsignatura" :class="{'p-invalid':v$.selectedAsignatura.$invalid && submitted}">
                            <template #empty>
                                <div class="">Seleccione Facultad </div>
                            </template>
                            <template #value="slotProps">
                                <div  v-if="slotProps.value">
                                    <div>{{slotProps.value.plm_mat_nombre}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div class="ml-1">{{slotProps.option.plm_mat_nombre}}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small v-if="(v$.selectedAsignatura.$invalid && submitted) || v$.selectedAsignatura.$pending.$response" class="p-error">{{v$.selectedAsignatura.required.$message}}</small>
                    </div>
                </div>
            </div>
            <Divider type="dashed"  class="p-divider-danger my-0"/>
            <div class="flex flex-column justify-content-center my-2">
                <h5  class="font-semibold text-lg pb-0 mb-0 "><AngleDoublesvg/>Detalles de copia</h5>
                <span class="text-sm font-semibold text-gray-500 ml-3">Se copiará la siguiente información</span>   
            </div>
            <div class="grid p-fluid ml-3" >
                <div class="col-12 " >
                    <div class="field-checkbox">
                        <Checkbox  id="prm1" :binary="true"  v-model="parametrosCopy.prm_generalidades" />
                        <label for="prm1">Copiar Generalidades de plan</label>
                    </div>
                    <div class="field-checkbox">
                        <Checkbox  id="prm2" :binary="true"  v-model="parametrosCopy.prm_competencias" />
                        <label for="prm2">Copiar Planeación de la competencia</label>
                    </div>
                    <div class="field-checkbox">
                        <Checkbox  id="prm3" :binary="true"  v-model="parametrosCopy.prm_recursos" />
                        <label for="prm3">Copiar Recursos didácticos ( Bibliografías)</label>
                    </div>
                     <div class="field-checkbox">
                        <Checkbox  id="prm4" :binary="true"  v-model="parametrosCopy.prm_comision" />
                        <label for="prm4">Copiar esquema de la comisión  ( Docentes )</label>
                    </div>
                </div>
            </div>
            <Button type="submit" label="Comenzar Copia..." class="mt-2" :loading="loadingCreate"/>
        </form>
    </Dialog>
</template>



<script>

import FacultadService from '@/service/facultadService'
import GeneralService from '@/service/generalService'
import PlanDidacticoService from '@/service/planDidacticoService'
import AngleDoublesvg from '../../svg/AngleDoublesvg';

//import EmpleadoDataTable from "../../Generales/EmpleadoDt"

//Library
import { required, helpers  } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import methods from "@/utilities/generalMethods.js"


export default {
    
    props: {
        dataPlan : {
           type : Object,
            default(){
                return {}
            }
        },
        isVisible :{
            type : Boolean,
            default :  false
        }, // Visible
    },

    emits : ["update:isVisible", 'cancel_onClick','save_onClick'],
    facultadService : null,
    generalService  : null,
    planDidacticoService :  null,
    setup: () => ({ v$: useVuelidate() }),
    data(){
       
        return{

            selectedPlan : {},
            
            cicloList : [],
            selectedCiclo : null,

            facultadList : [],
            selectedFacultad : null,

            carreraList : [],
            selectedCarrera : null,

            asignaturaList : [],
            selectedAsignatura : null,

            planAcademicoList : [],
            selectedPlanAcademico : null,
            //
            loadingCarrera : true,
            loadingAsignatura : true,
            loadingPlan : true,
            loadingCreate : false, 

            createVisible :  this.isVisible, //Modal show/hide

            submitted : false,

            parametrosCopy : {
                prm_generalidades : true,
                prm_competencias :  true,
                prm_recursos :  true,
                prm_comision: true,
            }
        }

    },

    validations() {
        return {
             selectedCiclo: {
                required:  helpers.withMessage(
                    () => `* Seleccione un ciclo válido`,
                    required)
            },
            selectedFacultad: {
                required:  helpers.withMessage(
                        () => `* Seleccione una facultad válida`,
                        required
                    )
            },
            selectedCarrera: {
                required:  helpers.withMessage(
                        () => `* Seleccione una carrera válida`,
                        required
                    )
            },
            selectedPlanAcademico: {
                required:  helpers.withMessage(
                        () => `* No válido`,
                        required
                    )
            },
            selectedAsignatura: {
                required:  helpers.withMessage(
                        () => `* No válido`,
                        required
                    )
            },

        }
    },
    created(){
        this.facultadService =  new FacultadService(this.axios)
        this.generalService =  new GeneralService(this.axios)
        this.planDidacticoService =  new PlanDidacticoService(this.axios) 
    },

    async mounted(){
        try{
            await this.facultadService.getAllFacultad().then(result => {
                this.facultadList =  result
                var fac  =  this.facultadList.filter(x=> x.fac_codigo == this.$store.state.uEmpleado.emp_codfac);
                
                if(fac.length > 0){
                    this.selectedFacultad =  fac[0];
                    this.facultadList =  fac;
                    this.selFacultad_onChange({ value :  { fac_codigo :  this.selectedFacultad.fac_codigo} })
                }
            })
            await this.generalService.getCiclo().then(result => this.cicloList  =  result )
        }catch(ex){
            var msg = methods.messageCode(ex)
            this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
            this.$catchError(ex)
        }
    },
    methods:{

        async  fx_initCopy (){
            const fac =  this.facultadList.find(x=> x.fac_nombre === this.selectedPlan.fac_nombre)
            this.selectedFacultad =  fac
            await this.selFacultad_onChange({ value : fac})
            const car =  this.carreraList.find(x=> x.car_nombre === this.selectedPlan.car_nombre)
            this.selectedCarrera =  car
            await this.selCarrera_onChange({ value:  car })
            this.selectedCiclo =  null;

            //Ciclo
            const currentCiclo  = this.cicloList.find(x=> x.cil_descripcion === this.selectedPlan.pld_nomcil)
            if(currentCiclo){
                this.cicloList =  this.cicloList.filter(x=> x.cil_codigo >= currentCiclo.cil_codigo)
            }
        },

        hide_event(){
            this.$emit('cancel_onClick')
        },

        async handleSubmit(isFormValid) {

            try{
                this.submitted = true;
                if (!isFormValid) {
                    return;
                }

                //Restrinción Extra.
                var indexVigente =  this.cicloList.findIndex(x=> x.cil_vigente =="S");
                var cicloVigente =  indexVigente <= -1 ? null : this.cicloList[indexVigente] 
                if(this.selectedCiclo.cil_codigo < cicloVigente.cil_codigo)
                {
                    throw `El ciclo de plan didáctico a crear "${this.selectedCiclo.cil_descripcion}"  no puede ser menor al ciclo vigente "${cicloVigente.cil_descripcion}"`
                }

                this.loadingCreate = true;
                await new Promise(resolve => {
                    setTimeout(async () => {
                        this.loadingCreate = false;
                        await this.planDidacticoService.copyPlan(
                        {
                           
                            codcil: this.selectedCiclo.cil_codigo,
                            nomcil: this.selectedCiclo.cil_descripcion,
                            fechacil: this.selectedCiclo.cil_fecha_ini,
                            codplm:  this.selectedAsignatura.plm_codigo,

                            generalidades: this.parametrosCopy.prm_generalidades,
                            competencias: this.parametrosCopy.prm_competencias,
                            recursos: this.parametrosCopy.prm_recursos,
                            comision: this.parametrosCopy.prm_comision,
                            codigo :  this.selectedPlan.pld_codigo
                            }).then(result=> {
                                // this.planDidacticoService.createComision(result.codigo,this.comisionList).then(res=> res);
                                
                                if(result){
                                    this.$router.push({
                                        name: "PlaneacionDetalle",
                                        params:{
                                            id: result.codigo,
                                        }
                                    });
                                }
                        });
                        resolve();
                    }, 1000);
                    });

                this.$emit('save_onClick')
                this.createVisible =  false;
            }catch(ex){
                var msg = methods.messageCode(ex)
                this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
            }
        },

         
         
        async selFacultad_onChange(event){
         
            this.selectedCarrera = null;
            this.selectedAsignatura = null;
            this.loadingCarrera = true;
            await this.facultadService.getCarreraByFacultad(event.value.fac_codigo).then(data => this.carreraList = data)
            this.loadingCarrera = false;
        },

        async selCarrera_onChange(event){

            this.selectedPlanAcademico = null;
            this.loadingPlan = true;

            if(event.value === null){
                this.planAcademicoList = [];
                return;
            }
            await this.facultadService.getPlanByCarrera(event.value.car_codigo).then(result => this.planAcademicoList =  result)
            this.loadingPlan = false;

        },

        async selPlanAcad_onChange(event){
            this.selectedAsignatura = null;
            this.loadingAsignatura = true;

            if(event.value === null){
                this.asignaturaList = [];
                return;
            }

            await this.facultadService.getAsignaturaByPlanAcad(event.value.pla_codigo).then(result => this.asignaturaList =  result)
            this.loadingAsignatura = false;
        },


    },
    updated() {
        if (this.isVisible) {
            this.createVisible = this.isVisible;
            this.selectedPlan  =  this.dataPlan;
            this.fx_initCopy()
        }
        
    },
    components:{
        AngleDoublesvg
        //"EmpleadoDataTable" :  EmpleadoDataTable
    }


   
}
</script>
